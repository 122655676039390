import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import rtlDetect from 'rtl-detect';
import { isDirectionRTL } from 'fbjs/lib/UnicodeBidi';
import InputText from './input-text';
import { getLanguage } from '../../../common/selectors/app-data-selectors';

export const DirectionAwareInputText = ({ isSiteRtl, isValueRtl, ...props }) => (
  <InputText {...props} dir={isSiteRtl && isValueRtl ? 'rtl' : undefined} />
);

DirectionAwareInputText.propTypes = {
  isSiteRtl: PropTypes.bool,
  isValueRtl: PropTypes.bool,
};

const mapRuntimeToProps = (state, { value }) => ({
  isSiteRtl: rtlDetect.isRtlLang(getLanguage(state)),
  isValueRtl: isDirectionRTL(value),
});

export default connect(mapRuntimeToProps)(DirectionAwareInputText);
