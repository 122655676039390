import { map, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import RecentPostsListItem from '../recent-posts-list-item';
import withTranslate from '../../../common/hoc/with-translate';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import withPostBorderWidth from '../../../common/hoc/with-post-border-width';
import withRecentPostListItemSize from '../../hoc/with-recent-post-list-item-size';
import styles from './recent-posts-list.scss';

export const RecentPostsList = ({ posts = [], borderWidth, isMetadataFooterVisible, width, height }) => {
  const itemClassName = classNames(
    styles.listItem,
    'blog-card-background-color',
    'blog-card-border-color',
    'recent-posts-list__list-item',
  );

  const itemStyle = {
    borderWidth,
    height: height + (isMetadataFooterVisible ? 148 : 103),
    flexBasis: width + borderWidth * 2,
  };

  return (
    <div className={styles.container}>
      {map(posts, post => (
        <div className={itemClassName} style={itemStyle} key={post._id}>
          <RecentPostsListItem post={post} height={height} width={width} />
        </div>
      ))}
    </div>
  );
};

RecentPostsList.propTypes = {
  posts: PropTypes.array,
  borderWidth: PropTypes.number,
  t: PropTypes.func.isRequired,
  isMetadataFooterVisible: PropTypes.bool.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

// prettier-ignore
export default flowRight(
  withTranslate,
  withFeedMetadataSettings,
  withPostBorderWidth,
  withRecentPostListItemSize,
)(RecentPostsList);
