import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import RecentPostsListItemWithCover from './recent-posts-list-item-with-cover';
import RecentPostsListItemWithoutCover from './recent-posts-list-item-without-cover';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import { getPostCover } from '../../../common/services/get-post-cover';
import styles from './recent-posts-list-item.scss';

export const RecentPostsListItem = props => {
  const { post, incrementPostLikeCount } = props;
  const onLikeClick = () => incrementPostLikeCount(post._id);
  const { shouldRender: withCover } = getPostCover(post);
  const postLink = `/${post.slug}`;
  const Component = withCover ? RecentPostsListItemWithCover : RecentPostsListItemWithoutCover;
  const componentProps = {
    postLink,
    type: 'recentPosts',
    onLikeClick,
    ...props,
  };

  return (
    <article className={styles.container} tabIndex="0" data-hook="recent-post-list-item">
      <Component {...componentProps} />
    </article>
  );
};

RecentPostsListItem.propTypes = {
  post: PropTypes.object.isRequired,
  incrementPostLikeCount: PropTypes.func.isRequired,
  isMetadataFooterVisible: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  incrementPostLikeCount: actions.incrementPostLikeCount,
});

// prettier-ignore
export default flowRight(
  withFeedMetadataSettings,
  withFontClassName,
  connect(mapRuntimeToProps),
)(RecentPostsListItem);
