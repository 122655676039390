import getWordCount from '@wix/communities-blog-universal/dist/src/utils/get-word-count';

export default function getContentWordCount(contentState) {
  return contentState
    ? contentState
        .getBlockMap()
        .filter(block => block.getType() !== 'atomic')
        .reduce((count, block) => getWordCount(block.getText()) + count, 0)
    : 0;
}
