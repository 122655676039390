import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import DotDotDot from 'react-dotdotdot';

import { RECENT_POSTS } from '../../../common/services/get-post-cover-height';
import PostListItemCover from '../../../common/components/post-list-item-cover';
import { HorizontalSeparator } from '../../../common/components/separator';
import PostFooter from '../../../common/components/post-footer';
import withIsFeedDesignEnabled from '../../../common/hoc/with-is-feed-design-enabled';
import Link from '../../../common/components/link/internal-link';
import styles from './recent-posts-list-item.scss';

const RecentPostsListItemWithCover = ({
  post,
  postLink,
  onLikeClick,
  titleFontClassName,
  contentFontClassName,
  isMetadataFooterVisible,
  applyFeedDesign,
  getPostClassName,
  width,
  height,
}) => {
  const titleContainerClassName = classNames(!applyFeedDesign && titleFontClassName, getPostClassName('title-font'));
  const titleClassName = classNames(styles.titleWrapper, 'blog-post-title-color');
  const containerClassName = classNames(
    styles.containerWithCover,
    !isMetadataFooterVisible && styles.withoutFooter,
    !applyFeedDesign && contentFontClassName,
    getPostClassName('description-font'),
  );

  const renderTitle = (
    <div className={titleContainerClassName} data-hook="recent-post__title">
      <h4 className={titleClassName}>
        <Link className={styles.title} to={postLink}>
          <DotDotDot clamp={2}>{post.title}</DotDotDot>
        </Link>
      </h4>
    </div>
  );

  const renderFooter = (
    <div className={styles.footerWrapper}>
      <HorizontalSeparator className={styles.separator} />
      <div className={styles.footer}>
        <PostFooter {...{ post, postLink, onLikeClick, displayIcons: true }} />
      </div>
    </div>
  );

  return (
    <div className={containerClassName}>
      <PostListItemCover
        post={post}
        postLink={postLink}
        type={RECENT_POSTS}
        videoClassName={styles.videoThumbnail}
        width={width}
        height={height}
      />
      <div className={classNames(styles.main, 'with-cover')}>
        {renderTitle}
        {isMetadataFooterVisible && renderFooter}
      </div>
    </div>
  );
};

RecentPostsListItemWithCover.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
  getPostClassName: PropTypes.func,
  applyFeedDesign: PropTypes.bool.isRequired,
  isMetadataFooterVisible: PropTypes.bool.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  postLink: PropTypes.string.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default flowRight(withIsFeedDesignEnabled)(RecentPostsListItemWithCover);
