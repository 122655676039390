import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';

import withTranslate from '../../../common/hoc/with-translate';
import { getPostEditorPost } from '../../../common/store/post-editor/post-editor-selectors';
import { isScheduled } from '../../../common/selectors/post-selectors';

import { isPublished } from '@wix/communities-blog-universal/dist/src/post-utils';
import { hasUnpublishedChanges } from '../../../common/services/post-utils';
import DropdownButton from '../../../common/components/dropdown-button';
import PostFormActions from '../../containers/post-form-actions';
import { getIsDesktop } from '../../../common/store/basic-params/basic-params-selectors';
import styles from './post-form-dropdown-button.scss';

export const PostFormDropdownButton = ({
  postId,
  hasUnpublishedChanges,
  isDesktop,
  isPublished,
  isScheduled,
  buttonProps,
  isPostFormFieldsEmpty,
  isCtaDisabled,
  onClickSave,
  onClickPublish,
  onClickCancel,
}) => {
  const actions = isScheduled
    ? [
        PostFormActions.ACTION_PUBLISH_SCHEDULED,
        PostFormActions.ACTION_REVERT_SCHEDULED_TO_DRAFT,
        PostFormActions.ACTION_RESCHEDULE,
      ]
    : [
        isDesktop ? PostFormActions.ACTION_POST_SETTINGS : null,
        PostFormActions.ACTION_ADD_CATEGORY,
        PostFormActions.ACTION_SAVE,
        hasUnpublishedChanges ? PostFormActions.ACTION_DISCARD : null,
      ];

  return (
    <span className={styles.container} data-hook="post-form__action-button">
      <DropdownButton top buttonProps={buttonProps}>
        <PostFormActions
          postId={postId}
          isPublished={isPublished}
          actions={actions}
          isPostFormFieldsEmpty={isPostFormFieldsEmpty}
          isCtaDisabled={isCtaDisabled}
          onClickSave={onClickSave}
          onClickPublish={onClickPublish}
          onClickCancel={onClickCancel}
        />
      </DropdownButton>
    </span>
  );
};

PostFormDropdownButton.propTypes = {
  postId: PropTypes.string,
  hasUnpublishedChanges: PropTypes.bool,
  isPublished: PropTypes.bool,
  isScheduled: PropTypes.bool,
  isDesktop: PropTypes.bool,
  buttonProps: PropTypes.object.isRequired,
  isRichContentEditorEnabled: PropTypes.bool,
  isPostFormFieldsEmpty: PropTypes.bool,
  isCtaDisabled: PropTypes.bool,
  onClickSave: PropTypes.func,
  onClickPublish: PropTypes.func,
  onClickCancel: PropTypes.func,
};

const mapRuntimeToProps = state => {
  const post = getPostEditorPost(state) || {};
  return {
    postId: post._id,
    hasUnpublishedChanges: hasUnpublishedChanges(post, state),
    isPublished: isPublished(post),
    isScheduled: isScheduled(post),
    isDesktop: getIsDesktop(state),
  };
};

export default flowRight(connect(mapRuntimeToProps), withTranslate)(PostFormDropdownButton);
