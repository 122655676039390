import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';

import { isPostEditorDeprecated } from '../../../common/selectors/experiments-selectors';
import { getPostEditorDraftKey } from '../../../common/store/post-editor/post-editor-selectors';

import styles from './post-editor-modal-loader.scss';

class PostEditorModalLoader extends Component {
  componentDidMount() {
    if (this.props.isPostEditorDeprecated) {
      this.props.goToFeed();
    }
  }

  componentWillUnmount() {
    this.props.cleanPostEditorState(this.props.draftKey);
  }

  render() {
    return <div className={styles.container} />;
  }
}

PostEditorModalLoader.propTypes = {
  goToFeed: PropTypes.func.isRequired,
  draftKey: PropTypes.string,
  isPostEditorDeprecated: PropTypes.bool.isRequired,
  route: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  cleanPostEditorState: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  goToFeed: actions.goToFeed,
  draftKey: getPostEditorDraftKey(state),
  isPostEditorDeprecated: isPostEditorDeprecated(state),
  cleanPostEditorState: actions.cleanPostEditorState,
});

export default connect(mapRuntimeToProps)(PostEditorModalLoader);
