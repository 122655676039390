import { flowRight, debounce, memoize } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../common/components/runtime-context';
import hoistNonReactStatics from 'hoist-non-react-statics';
import withPostBorderWidth from '../../common/hoc/with-post-border-width';
import { ITEM_COVER_CONFIG, RECENT_POSTS } from '../../common/services/get-post-cover-height';
import withResponsiveContext from '../../common/components/responsive-listener/with-responsive-context';
import { isEditor } from '../../common/selectors/view-mode-selectors';

const WINDOW_PADDING = 40;
const IMAGE_ASPECT_RATIO = 16 / 9;
const WINDOW_WIDTH_WITH_2_POSTS = 686;
const WINDOW_WIDTH_WITH_3_POSTS = 980;
const MARGIN_BETWEEN_POSTS = 17;
const DEBOUNCE_TIME = 1000 / 60;

const getDimensionsFunc = (containerWidth, borderWidth) => {
  const fullBorderWidth = borderWidth * 2;
  if (containerWidth >= WINDOW_WIDTH_WITH_3_POSTS) {
    return {
      width: ITEM_COVER_CONFIG[RECENT_POSTS].fixedWidth,
      height: ITEM_COVER_CONFIG[RECENT_POSTS].fixedHeight,
    };
  }

  if (containerWidth < WINDOW_WIDTH_WITH_2_POSTS) {
    const width = containerWidth - WINDOW_PADDING - fullBorderWidth;
    return {
      width,
      height: Math.round(width / IMAGE_ASPECT_RATIO),
    };
  }

  const width = (containerWidth - WINDOW_PADDING) / 2 - MARGIN_BETWEEN_POSTS - fullBorderWidth;
  const height = Math.round(width / IMAGE_ASPECT_RATIO);
  return { width, height };
};
const getDimensions = memoize(getDimensionsFunc);

const withRecentPostListItemSize = WrappedComponent => {
  class WithRecentPostListItemSize extends Component {
    state = getDimensions(this.props.rootWidth, this.props.borderWidth);

    setDimensions = () => {
      const dimensions = getDimensions(window.innerWidth, this.props.borderWidth);
      if (dimensions.width !== this.state.width || dimensions.height !== this.state.height) {
        this.setState(dimensions);
      }
    };

    handleResize = debounce(this.setDimensions, DEBOUNCE_TIME);

    componentDidMount() {
      if (this.props.isEditor) {
        window.addEventListener('resize', this.handleResize, false);
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize, false);
    }

    render = () => <WrappedComponent width={this.state.width} height={this.state.height} {...this.props} />;
  }

  WithRecentPostListItemSize.propTypes = {
    borderWidth: PropTypes.number.isRequired,
    rootWidth: PropTypes.number.isRequired,
    isEditor: PropTypes.bool.isRequired,
  };

  const mapRuntimeToProps = state => ({
    isEditor: isEditor(state),
  });

  hoistNonReactStatics(WithRecentPostListItemSize, WrappedComponent);

  return flowRight(withPostBorderWidth, withResponsiveContext, connect(mapRuntimeToProps))(WithRecentPostListItemSize);
};

export default withRecentPostListItemSize;
